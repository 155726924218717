import Navbar from "./Navbar";
import styles from "./styles/Home.module.css";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Companies from "./Companies";
import { Box, Container } from "@mui/system";
import { AuthContext } from "../App";

const Home = (props) => {
  const { state } = useContext(AuthContext);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container>
        <Navbar abbvr="Hello" name={state.user.first_name.toUpperCase()} />
        <h2 className={styles.statsTitle}>
          <span className="yellow-color">Your</span> stats
        </h2>
        <div className={styles.stats}>
          <div className={styles.approached}>
            <p className={styles.statNumber}>-</p> <p>approached</p>
          </div>
          <div className={styles.responded}>
            <p className={styles.statNumber}>-</p> <p>responded</p>
          </div>
          <div className={styles.done}>
            <p className={styles.statNumber}>-</p> <p>done</p>
          </div>
        </div>
        <div className={styles.companyTitles}>
          <div>
            My <span className="yellow-color">Companies</span>
          </div>
          <div>
            <Link to="/addcompany" className={styles.smol}>
              <span className={styles.smol}>
                <span className="yellow-color">Add</span> Company{" "}
              </span>
              <span className={styles.addButton}>+</span>
            </Link>
          </div>
        </div>
        <div className={styles.companyTable}>
          <Companies />
        </div>
        {/* <div className={styles.homeFooter}>
          <Link to="/emailtemplate" className={styles.smol}>
            To view email template,{" "}
            <span>
              <span className="yellow-color">click here</span>
            </span>
          </Link>
        </div> */}
      </Container>
    </Box>
  );
};

export default Home;
