import React from 'react'
import Navbar from './Navbar'

export default function Email() {
  return (
    <>
      <Navbar name="Temp" abbvr="Email" />
    </>
  )
}
