import React, { useContext } from "react";
import styles from "./styles/Navbar.module.css";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { AuthContext } from "../App";
// import React, { useState, useEffect } from 'react'

// export default function UserWindow() {
//   const [screenSize, getDimension] = useState({
//     dynamicWidth: window.innerWidth,
//     dynamicHeight: window.innerHeight
//   });
//   const setDimension = () => {
//     getDimension({
//       dynamicWidth: window.innerWidth,
//       dynamicHeight: window.innerHeight
//     })
//   }

const Navbar = (props) => {
  const { state, dispatch } = useContext(AuthContext);

  return (
    <div className={styles.NavStack}>
      <Link to="/">
        <Button variant="outlined" className={styles.buttontop}>
          My Profile
        </Button>
      </Link>
      <div className={styles.hub}>
        <span>{props.abbvr || "Hello"}</span>
        <span>{props.name || state.user.first_name.toUpperCase()}</span>
      </div>
      <Button
        variant="outlined"
        className={styles.buttontop}
        onClick={() =>
          dispatch({
            type: "LOGOUT",
          })
        }
      >
        Logout
      </Button>
    </div>
  );
};

export default Navbar;
