import { Box, List, ListItem, Modal, Typography, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles/Home.module.css";
const CompanyModal = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    Width: 500,
    minWidth: 350,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.modal}>
          <Typography
            id="modal-modal-title"
            align="center"
            variant="h4"
            component="h2"
          >
            {props.data.name}
          </Typography>
          <List>
            <ListItem>Person: {props.data.person}</ListItem>
            <ListItem>Status: {props.data.status}</ListItem>
            <ListItem>Email: {props.data.email}</ListItem>
            <ListItem>Phone: {props.data.contact}</ListItem>
            <ListItem>Contacted At: {props.data.last_contacted}</ListItem>
            <ListItem>Last Contacted: {props.data.contacted_at}</ListItem>
            <ListItem>Notes: {props.data.notes}</ListItem>
            <ListItem>Page: {props.data.page}</ListItem>
            <ListItem>Socials: {props.data.socials}</ListItem>
          </List>
          <Button variant="outlined" color="primary">
            <Link to={`company/${props.data.id}`} state={{ data: props.data }}>
              Edit
            </Link>
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default CompanyModal;
