import React, { useState, useContext } from "react";
import Navbar from "./Navbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Box,
  Typography,
  List,
  ListItem,
  TextField,
  Select,
  MenuItem,
  Container,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles/Add.module.css";
import DoneLottie from "../assets/done-lottie.json";
import Lottie from "lottie-react";
import { AuthContext } from "../App";
import axios from "axios";

const contact_status = ["Pending", "In Progress", "Accepted", "Rejected"];

const UpdateCompany = () => {
  const location = useLocation();
  const { data } = location.state;
  const [addLoading, setAddLoading] = useState(false);
  const [lottie, setLottie] = useState(false);
  console.log(data);
  const [value, setValue] = useState(data.last_contacted);
  const [snackbarMessage, setMessage] = useState({
    type: "success",
    message: "",
    open: false,
  });
  const { state } = useContext(AuthContext);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  function updateForm(e) {
    setAddLoading(true);
    e.preventDefault();
    console.dir(e.target);
    const payload = {
      company_name: data.name,
      poc_name: e.target[0].value || data.person,
      email_poc: e.target[2].value || data.email,
      mob_no_poc: e.target[4].value || data.contact,
      contact_status: parseInt(e.target[6].value) || data.statusNumber,
      social_media_contacts: e.target[8].value || data.socials,
      website: e.target[10].value || data.page,
      notes: e.target[12].value || data.notes,
      contacted_at: e.target[14].value || data.last_contacted,
    };
    console.log(payload);
    axios
      .put(
        `https://backend.marketing.mlsctiet.com/api/companies/update/${data.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setAddLoading(false);
        if (res.status < 400) {
          setMessage({
            type: "success",
            message: "Company Updated Successfully",
            open: true,
          });
          setLottie(true);
        } else {
          setAddLoading(false);
          setMessage({
            type: "error",
            message: "Something Went Wrong",
            open: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setAddLoading(false);
        setMessage({
          type: "error",
          message: "Something Went Wrong/Check Entries",
          open: true,
        });
      });
    setTimeout(() => {
      setMessage({
        type: "success",
        message: "",
        open: false,
      });
      setLottie(false);
    }, 5000);
  }

  return (
    <>
      <Navbar />
      {!location?.state ? (
        <div style={{ textAlign: "center", marginTop: "4rem" }}>
          <Typography variant="h4">Nothing to Update</Typography>
          <Link
            to="/"
            style={{ color: "lightblue", textDecoration: "underline" }}
          >
            Go Back
          </Link>
        </div>
      ) : (
        <Box>
          <Container sx={{ mt: 6 }}>
            <Typography align="center" variant="h4">
              {data.name}
            </Typography>
            <form
              className={styles.form}
              id="addCompanyForm"
              onSubmit={updateForm}
            >
              {/* <div className={styles.formElementContainer}>
                <label htmlFor="name" className={styles.label}>
                  Name of Company{" "}
                </label>
                <TextField
                  id="name"
                  variant="outlined"
                  value={data.name}
                  className={styles.inputform}
                  disabled
                />
              </div> */}
              <div className={styles.formElementContainer}>
                <label htmlFor="namePOC" className={styles.label}>
                  Name of Person/POC
                </label>
                <TextField
                  id="emailPOC"
                  variant="outlined"
                  placeholder={data.person}
                  className={styles.inputform}
                />
              </div>
              <div className={styles.formElementContainer}>
                <label htmlFor="emailPOC" className={styles.label}>
                  Email of Company/POC
                </label>
                <TextField
                  id="emailPOC"
                  variant="outlined"
                  placeholder={data.email}
                  className={styles.inputform}
                />
              </div>
              <div className={styles.formElementContainer}>
                <label htmlFor="mnumber" className={styles.label}>
                  Mobile Number of POC
                </label>
                <TextField
                  id="mnumber"
                  variant="outlined"
                  className={styles.inputform}
                  placeholder={data.contact}
                />
              </div>

              <div className={styles.formElementContainer}>
                <label htmlFor="status" className={styles.label}>
                  Status
                </label>
                <Select
                  labelId="status"
                  label="Status"
                  placeholder={data.statusNumber}
                >
                  <MenuItem value={1}>{contact_status[0]}</MenuItem>
                  <MenuItem value={2}>{contact_status[1]}</MenuItem>
                  <MenuItem value={3}>{contact_status[2]}</MenuItem>
                  <MenuItem value={4}>{contact_status[3]}</MenuItem>
                </Select>
              </div>
              <div className={styles.formElementContainer}>
                <label htmlFor="socials" className={styles.label}>
                  Socials
                </label>

                <TextField
                  id="socials"
                  variant="outlined"
                  placeholder={data.socials}
                />
              </div>
              <div className={styles.formElementContainer}>
                <label htmlFor="website" className={styles.label}>
                  Website/Page
                </label>
                <TextField
                  variant="outlined"
                  id="website"
                  placeholder={data.page}
                />
              </div>
              <div className={styles.formElementContainer}>
                <label htmlFor="notes" className={styles.label}>
                  Notes
                </label>
                <TextField
                  variant="outlined"
                  id="notes"
                  placeholder={data.notes}
                />
              </div>
              <div className={styles.formElementContainer}>
                <label htmlFor="approachedon" className={styles.label}>
                  Last approached on{" "}
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Last Contacted"
                    inputFormat="YYYY-MM-DD"
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.inputform}
                  />
                </LocalizationProvider>
              </div>
              <button
                type="submit"
                className={styles.lastlabel}
                styles={{ textAlign: "center" }}
              >
                {addLoading ? (
                  <CircularProgress color="inherit" />
                ) : lottie ? (
                  <Lottie
                    animationData={DoneLottie}
                    loop={true}
                    style={{ width: "50px" }}
                  />
                ) : (
                  "Update Company"
                )}
              </button>
            </form>
          </Container>
          <Snackbar open={snackbarMessage.open}>
            <Alert severity={snackbarMessage.type}>
              {snackbarMessage.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};

export default UpdateCompany;
