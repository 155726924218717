import React, { useState, useContext } from "react";
import styles from "./styles/Login.module.css";
import hero from "../assets/login_hero_crop.png";
import axios from "axios";
import { AuthContext } from "../App";
import { Snackbar, Alert, CircularProgress } from "@mui/material";

const Login = () => {
  const { dispatch } = useContext(AuthContext);

  const [loginPage, setLoginPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({
    open: false,
    message: "",
    type: "error",
  });

  function loginViewHandle() {
    setLoginPage(true);
  }

  function signUpViewHandle() {
    setLoginPage(false);
  }

  const registerHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const name = e.target[0].value?.split(" ");
    const payload = {
      first_name: name[0],
      last_name:
        name[name.length - 1] && name[name.length - 1] !== name[0]
          ? name[name.length - 1]
          : " ",
      email: e.target[1].value,
      password: e.target[2].value,
      roll_number: e.target[3].value,
      phone_number: e.target[4].value,
    };
    console.log(payload);
    axios
      .post(
        "https://backend.marketing.mlsctiet.com/api/users/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status < 400) {
          setLoading(false);
          setErr({
            open: true,
            message: "Registered Successfully",
            type: "success",
          });
          const user = { ...res.data };
          delete user.token;
          dispatch({
            type: "LOGIN",
            payload: {
              user: user,
              token: res.data.token,
            },
          });
        } else {
          setLoading(false);
          setErr({
            open: true,
            message: "Wrong Parameters",
            type: "error",
          });
        }
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
        setErr({
          open: true,
          message: "Internal Server Error/Check Credentials",
          type: "error",
        });
      });
    setTimeout(() => {
      setErr({ open: false, message: "", type: "error" });
    }, 3000);
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email_or_roll: e.target[0].value,
      password: e.target[1].value,
    };
    console.log(payload);
    await axios
      .post(
        "https://backend.marketing.mlsctiet.com/api/users/signin",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status < 400) {
          setLoading(false);
          setErr({
            open: true,
            message: "Logged In Successfully",
            type: "success",
          });
          const user = { ...res.data };
          delete user.token;
          dispatch({
            type: "LOGIN",
            payload: {
              user: user,
              token: res.data.token,
            },
          });
        } else {
          setLoading(false);
          setErr({
            open: true,
            message: "Invalid Credentials",
            type: "error",
          });
        }
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
        setErr({
          open: true,
          message: "Invalid Credentials",
          type: "error",
        });
      });
    setTimeout(() => {
      setErr({ open: false, message: "", type: "error" });
    }, 3000);
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.leftContainer}>
          <div className={styles.leftContainerHeader}>
            <span
              onClick={loginViewHandle}
              className={loginPage ? styles.underline : ""}
            >
              Login
            </span>
            <span
              onClick={signUpViewHandle}
              className={!loginPage ? styles.underline : ""}
            >
              Sign Up
            </span>
          </div>
          {loginPage ? (
            <form className={styles.loginFormContainer} onSubmit={loginHandler}>
              <div className={styles.formElement}>
                <label htmlFor="email_login">Roll Number</label>
                <input
                  type="number"
                  id="email_login"
                  required
                  placeholder="102115234"
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="password_login">Password*</label>
                <input
                  type="password"
                  id="password_login"
                  required
                  defaultValue=""
                  placeholder="********"
                />
              </div>
              {/* <input
                type="submit"
                value={loading ? <CircularProgress color="inherit" /> : "LOGIN"}
                className={styles.formSubmit}
              /> */}
              <button type="submit" className={styles.formSubmit}>
                {loading ? <CircularProgress color="inherit" /> : "LOGIN"}
              </button>
            </form>
          ) : (
            <form
              className={styles.signupFormContainer}
              onSubmit={registerHandler}
            >
              <div className={styles.formElement}>
                <label htmlFor="name_signup">Enter your Name</label>
                <input
                  type="text"
                  id="name_signup"
                  required
                  placeholder="John Doe"
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="email_signup">Enter your Email</label>
                <input
                  type="text"
                  id="email_signup"
                  required
                  placeholder="example@thapar.edu"
                  pattern="[a-z0-9._%+-]+@[thapar]+\.[edu]{2,3}"
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="password_signup">Enter your Password</label>
                <input
                  type="password"
                  id="password_signup"
                  required
                  placeholder="********"
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="rollnumber_signup">Roll Number</label>
                <input
                  type="text"
                  id="rollnumber_signup"
                  required
                  placeholder="123456789"
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="number_signup">
                  Enter your WhatsApp Number
                </label>
                <input
                  type="text"
                  id="number_signup"
                  required
                  placeholder="1234567890"
                />
              </div>
              <button type="submit" className={styles.formSubmit}>
                {loading ? <CircularProgress color="inherit" /> : "SIGN UP"}
              </button>
            </form>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <img
          src={hero}
          className={styles.rightImage}
          alt="Microsoft Learn Student Chapter"
        />
      </div>
      <Snackbar open={err.open}>
        <Alert severity={err.type}>{err.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
