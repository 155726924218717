import React from "react";
import styles from "./styles/Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <p>Made with &#x2764;, by MLSC TIET.</p>
      <p>For any issues/queries, contact admins.</p>
    </div>
  );
};

export default Footer;
