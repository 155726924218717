import React, { createContext, useReducer } from "react";
import Login from "./components/Login";
import Home from "./components/Home";
import AddCompany from "./components/AddCompany";
import UpdateCompany from "./components/UpdateCompany";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Email from "./components/email";
import { CookiesProvider, useCookies } from "react-cookie";

export const AuthContext = createContext();

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["marketing-user"]);

  const userState = {
    isAuthenticated: cookies["marketing-user"] ? true : false,
    user: cookies["marketing-user"] ? cookies["marketing-user"] : null,
    token: cookies["marketing-token"] ? cookies["marketing-token"] : null,
  };

  const userReducer = (state, action) => {
    switch (action.type) {
      case "LOGIN":
        setCookie("marketing-user", action.payload.user, {
          path: "/",
          maxAge: 3600 * 24,
        });
        setCookie("marketing-token", action.payload.token, {
          path: "/",
          maxAge: 3600 * 24,
        });
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload.user,
          token: action.payload.token,
        };
      case "LOGOUT":
        removeCookie("marketing-user", { path: "/" });
        removeCookie("marketing-token", { path: "/" });
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          token: null,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(userReducer, userState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <CookiesProvider>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={!state.isAuthenticated ? <Login /> : <Home />}
              />
              <Route
                exact
                path="/addcompany"
                element={
                  state.isAuthenticated ? <AddCompany /> : <h1>Login First</h1>
                }
              />
              <Route
                exact
                path="/emailtemplate"
                element={
                  state.isAuthenticated ? <Email /> : <h1>Login First</h1>
                }
              />
              <Route
                path="/company/:id"
                element={
                  state.isAuthenticated ? (
                    <UpdateCompany />
                  ) : (
                    <h1>Login First</h1>
                  )
                }
              />
              <Route
                path="*"
                element={
                  <div style={{ textAlign: "center", marginTop: "4rem" }}>
                    <h1>404, No such page found</h1>
                    <a
                      href="/"
                      style={{
                        color: "lightblue",
                        textDecoration: "underline",
                      }}
                    >
                      Return to Main Website
                    </a>
                  </div>
                }
              />
            </Routes>
            <Footer />
          </Router>
        </ThemeProvider>
      </CookiesProvider>
    </AuthContext.Provider>
  );
}

export default App;
